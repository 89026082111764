<template>
  <div class="view pa24">
    <el-form :model="serchParams" class="demo-form-inline" :inline="true">
          <el-row style="padding-top: 20px;">
<!--              <el-form-item v-if="labelName" :label="labelName" label-width="100px" label-position="left" class="fromItem" prop="dealerId">-->
<!--                <el-select v-model="serchParams.dealerId" placeholder="请选择" clearable>-->
<!--                  <el-option-->
<!--                    v-for="item in dealerOptions"-->
<!--                    :key="item.id"-->
<!--                    :label="item.dealerName"-->
<!--                    :value="item.id">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
               <el-form-item label="出库单号" label-width="70px" label-position="left" class="fromItem" prop="lotNumber">
                <el-input v-model="serchParams.lotNumber"></el-input>
              </el-form-item>
              <el-form-item label="状态" label-width="100px" label-position="left" class="fromItem" prop="status">
                <el-select v-model="serchParams.status" placeholder="请选择">
                  <el-option
                    v-for="item in statusSelect"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="订单时间" label-width="100px" label-position="left" class="fromItem" prop="startTime">
                <el-date-picker
                  v-model="serchParams.timeRange"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  >
                </el-date-picker>
              </el-form-item>
              <el-button type="primary" @click="search">搜索</el-button>
              <el-button @click="resetSearch">重置</el-button>
          </el-row>
      </el-form>
    <div class="d-flex">
      <el-button class="ma" type="primary" @click="handleAddOrder('add')">+添加出库订单</el-button>
      <el-button v-if="checkPermission(['wdtOrder'])" style="margin-left: 20px;" type="primary" @click="pullOrder">+模拟获取订单</el-button>
    </div>
    <commonTable
      :tableData="tableData"
      :total="total"
      :currentPage="pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:table>
        <!-- <el-table-column
            prop="classId"
            align="center"
            label="类别"
        /> -->
        <!-- <span class="copyTxt" @click="copy(scope.row.handle)"><img src="../../assets/images/fz.png" /></span> -->
        <el-table-column
          prop="lotNumber"
          align="center"
          label="出库单号"
		      width="160"
        >
        <template slot-scope="scope">
            {{scope.row.lotNumber}}<span class="copyTxt" @click="copy(scope.row.lotNumber)"><img src="../../assets/images/fz.png" /></span>
        </template>
        </el-table-column>
        <el-table-column
            prop="srcTradeNo"
            align="center"
            label="原始单号"
            width="200"
        >
        <template slot-scope="scope">
            {{scope.row.srcTradeNo||'--'}}<span class="copyTxt" @click="copy(scope.row.srcTradeNo)" v-if="scope.row.srcTradeNo"><img src="../../assets/images/fz.png" /></span>
        </template>
        </el-table-column>
         <el-table-column
            prop="code"
            align="center"
            :label="labelName"
        />
        <el-table-column
            prop="packNum"
            align="center"
            label="出库数量"
        >
          <template slot-scope="scope">
            {{scope.row.boxNum||0}}箱 + {{scope.row.packNum||0}}盒
          </template>
        </el-table-column>
        <el-table-column
            prop="packNum"
            align="center"
            label="已完成数量"
        >
          <template slot-scope="scope">
            <!-- {{scope.row.finishBoxNum||0}}箱 + {{scope.row.finishPackNum||0}}盒 -->
            <span :class="(scope.row.status > 0  && (scope.row.finishBoxNum!==scope.row.boxNum)) ? 'redColor' : ''">{{scope.row.finishBoxNum||0}}</span>箱 +
            <span :class="(scope.row.status > 0 && (scope.row.finishPackNum!==scope.row.packNum)) ? 'redColor' : ''">{{scope.row.finishPackNum||0}}</span>盒
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
        />
        <el-table-column
            prop=""
            align="center"
            label="出库时间"
        />
        <el-table-column
            prop=""
            align="center"
            label="操作人"
        />
         <el-table-column
            prop="status"
            align="center"
            label="状态"
         >
         <template slot-scope="scope">
            <span v-if="scope.row.status === 0" class="greenTag">
             未开始
           </span>
           <span v-else-if="scope.row.status === 1" class="greenTag">
             进行中
           </span>
           <span v-else-if="scope.row.status === 2"  class="greyTag">
             已完成
           </span>
           <span v-else-if="scope.row.status === 3"  class="greyTag">
             已关闭
           </span>
           <span v-else-if="scope.row.status === 4"  class="redTag">
             已取消
           </span>
           <span v-else-if="scope.row.status === -1"  class="greyTag">
             待备货
           </span>
         </template>
         </el-table-column>
         <el-table-column
            prop="remarks"
            align="center"
            label="备注"
         ></el-table-column>
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="text" v-if="scope.row.revokeStatus == 0" @click="revoke(scope.row)">撤销</el-button> -->
            <el-button type="text" v-if="scope.row.status < 1" @click="handleAddOrder('edit', scope.row)">编辑</el-button>
            <el-button type="text" v-if="scope.row.status < 1" @click="delOrder(scope.row)" class="redBtn">删除</el-button>
            <el-button type="text"  @click="handleAddOrder('detail',scope.row)" >查看</el-button>

          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { warehouseLogPage,warehouseLogDetail, fetchCkOrderList, fetchRevoke, fetchDeleteCkOrder } from '@/api/warehouseManage.js'
import { pageClassList } from '@/api/customPageClass.js'
import { fetchDealList } from '@/api/companyManage.js'
import { selectWarehouseList ,pullWdtOrder} from '@/api/warehouseManage.js'
import {checkPermission} from "@/directives/permission";
export default {
  name: "dealerList",
  components: {
    commonTable,
  },
  data() {
    return {
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
        wareStatus: 2,
        serchParams: {
          classId: '',
          dealerId:'',
          timeRange: [],
          lotNumber: '',
          status: '',
        },
        statusSelect: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '未开始',
            value: 0,
          },
          {
            label: '已完成',
            value: 2,
          },
          {
            label: '已关闭',
            value: 3,
          },
          {
            label: '待备货',
            value: -1,
          },
        ],
        classOptions:[],
        dealerOptions:[],
        storeOptions:[],
        labelName: '',
    };
  },
  created() {
    // 获取所有分类
    this.getClassList()
    // 获取所有代理商、经销商列表
    this.getDealerList()
    // 获取所有仓库列表
    // this.getStoreList()
    this.getList()
    let userInfo = JSON.parse(localStorage.getItem('info'));
    let roleType = userInfo.userType
    if(roleType == 3) { // 代理商,获取经销商列表
      this.labelName='经销商/区域'
    }else if(roleType == 4) { // 经销商

    }else {
      this.labelName='代理商/区域'
    }
  },
  methods: {
    checkPermission,
    pullOrder(){
      pullWdtOrder().then(res=>{
        this.getList()
      })
    },
    detail(row){

    },
    classIdChange(){

    },
	  copy(context) {
          // 创建输入框元素
          let oInput = document.createElement('input');
          // 将想要复制的值
          oInput.value = context;
          // 页面底部追加输入框
          document.body.appendChild(oInput);
          // 选中输入框
          oInput.select();
          // 执行浏览器复制命令
          document.execCommand('Copy');
          // 弹出复制成功信息
          this.$message.success('复制成功');
          // 复制后移除输入框
          oInput.remove();
	    },
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      handleAddOrder(type, data) {
          // this.$refs.addOrderDialog.openDialog(type, data)
          this.$router.push({
            path: '/crk/ckOrderAdd',
            query:{hasSetting:this.hasSetting}
          })
          if(data) {
            this.$router.push({ path: `/crk/ckOrderAdd`, query: { data: JSON.stringify(data), type:type,hasSetting:this.hasSetting} })
          }
      },

      // 过滤掉空的children
      formatData(data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].children == null || data[i].children.length == 0) {
            data[i].children = undefined;
          } else if (data[i].children.length > 1) {
            this.formatData(data[i].children);
          }
        }
        return data;
      },
      getClassList() {
        let params={
          pageNum: 1,
          pageSize: 999,
          type:0
        }
        pageClassList(params).then(res => {
          this.classOptions = this.formatData(res.data.list)
        })
      },
      getStoreList() {
        let params={
          pageNum: 1,
          pageSize: 999,
          companyId: JSON.parse(localStorage.getItem('info')).companyId,
        }
        selectWarehouseList(params).then(res => {
          this.storeOptions = res.data.list
        })
      },
      getDealerList() {
        let userInfo = JSON.parse(localStorage.getItem('info'));
        let roleType = userInfo.userType
        if(roleType == 3) {  // 代理商,获取经销商列表
          let params={
            pageNum: 1,
            pageSize: 999,
            type:2,
            companyId: JSON.parse(localStorage.getItem('info')).companyId,
          }
          fetchDealList(params).then(res => {
            this.dealerOptions = res.data.list
          })
        }else if(roleType == 4) { // 经销商

        }else { // 厂家，获取代理商
          let params={
            pageNum: 1,
            pageSize: 999,
            type:1,
            companyId: JSON.parse(localStorage.getItem('info')).companyId,
          }
          fetchDealList(params).then(res => {
            this.dealerOptions = res.data.list
          })
        }
      },
      search() {
        this.pageNum = 1
        this.getList()
      },
      resetSearch() {
        this.serchParams.classId = ''
        this.serchParams.dealerId = ''
        this.serchParams.timeRange = []
        this.serchParams.lotNumber = ''
        this.serchParams.status = ''
        this.pageNum = 1
        this.getList()
      },
      getList() {
        let params={
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          // classId: this.serchParams.classId,
          dealerId: this.serchParams.dealerId,
          startTime: this.serchParams.timeRange && this.serchParams.timeRange.length > 0 ? this.serchParams.timeRange[0] : '',
          endTime: this.serchParams.timeRange && this.serchParams.timeRange.length > 0 ? this.serchParams.timeRange[1] : '',
          lotNumber: this.serchParams.lotNumber,
          status: this.serchParams.status
        }
        fetchCkOrderList(params).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
        })
        // warehouseLogPage(params).then(res => {
        //   this.tableData = res.data.list
        //   this.total = res.data.total
        // })
      },
      revoke(row) {
      console.log(row.wareStatus)
        this.$confirm('此操作将撤销'+(+row.wareStatus===1?'入库':'出库')+', 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          fetchRevoke({batchId: row.id}).then(res => {
            if(res.code === 200) {
              this.getList()
              this.$message({
                type: 'success',
                message: '撤销成功!'
              });
            }
          })

        }).catch(() => {

        });

      },
      goDetail(row) {
        this.$router.push({path:'/crk/warehouseLog/outDetail',query: {id:row.id}})
      },
      boxDetail(data){
        this.$refs.boxDetail.init(data.id,this.wareStatus)
        this.$refs.boxDetail.dialogTableVisible= true
     },
     delOrder(row) {
       this.$confirm('此操作将永久删除该出库单, 是否继续?', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning'
       }).then(() => {
         fetchDeleteCkOrder({id: row.id}).then(res => {
           if(res.code === 200) {
             this.$message({
               type: 'success',
               message: '删除成功!'
             });
             this.getList()
           }
         })
       }).catch(() => {
       });
     }
  }
}
</script>

<style lang="scss" scoped>
	.name_status {
		display: inline-block;
		width: 26px;
		height: 17px;
		line-height: 15px;
		background: rgba(63, 114, 246, 0.11);
		border: 1px solid #3F72F6;
		border-radius: 4px;
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #3F72F6;
		text-align: center;
	}

	.name_status1 {
		display: inline-block;
		width: 26px;
		height: 17px;
		line-height: 15px;
		background: rgba(243, 136, 46, 0.11);
		border: 1px solid #F3882E;
		border-radius: 4px;
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #F3882E;
		text-align: center;
	}

	.copyTxt {
		display: inline-block;
		margin-left: 5px;
		cursor: pointer;
		color: #3F72F6;
	}
  .greenTag{
    color: #fff;
    background: #08A89B;
    border-radius: 6px;
    padding: 3px 5px;
    overflow: hidden;

  }
  .greyTag {
    background: #e5e5e5;
    color: #333;
    border-radius: 6px;
    padding: 3px 5px;
    overflow: hidden;

  }
  .redTag{
    background: #e5e5e5;
    color: #f00;
    border-radius: 6px;
    padding: 3px 5px;
    overflow: hidden;
  }
  .redBtn, .redColor{
    color: #f00;
  }
</style>

